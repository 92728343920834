@import "../../styles/colors.scss";
@import "public/assets/style/variables.scss";
@import "../../../public/assets/style/mixin.scss";

.activation-form-style {
  min-height: 400px;
  // width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;

  a {
    color: $active-color;
  }
}

.activate-device {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  section {
    align-items: center;
    display: flex;
    opacity: 0.8;
    width: 410px;
    border-radius: 15px;
    background: #000000cc;
    padding: 10px 40px;

    .text {
      text-align: end;
      margin: 0;
    }

    button {
      border-radius: 7px;
      margin-left: 20px;
      background-color: #d6d9da54;
      border-color: transparent;
      color: white;

      &:hover {
        background-color: #b80e0e;
        border-color: transparent;
        color: white;
      }
    }
  }
}

.packages-list {
  margin-top: 20px;
  // width: 640px;
  // display: grid;
  // // grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  // grid-template-columns: 1fr 1fr 1fr;
  // align-items: center;
  // justify-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-height: 40vh;
}

.package {
  background-color: #fff;
  background-image: url(https://i.ibb.co/WVrMT5x/pricing-pattern.png) !important;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 5px;
  color: #333333;
  cursor: pointer;
  flex-direction: column;
  height: 260px;
  padding: 80px 8px 50px;
  text-align: center;
  transition: 0.2s;
  width: 170px;

  &:hover {
    background-image: url("../../images/package_hover_bg.png");
  }

  .price {
    display: flex;
    justify-content: center;
    align-items: flex-end;

    font-size: 16px;
    gap: 3px;
    font-weight: bold;
    direction: rtl;
  }

  .name {
    color: #021678;
    font-size: 20px;
    font-weight: 800;
    line-height: 1.5;
    word-wrap: break-word;
  }

  .schedule-time {
    font-size: 20px;
  }

  .recomended {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 16px;
  }
}
.package.active {
  // margin-bottom: 40px;
  // height: 222px;
}
.activation-step2 {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;

  .checkboxes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .ant-checkbox-wrapper {
      margin: 0 !important;
      margin-top: 15px !important;
      font-size: 17px;
      a {
        color: white !important;

        &:hover {
          color: $blue !important;
        }
      }
    }
  }

  .warrning-text {
    width: 280px;
    text-align: center;
    color: #9d9191 !important;
    margin-bottom: 40px;
  }
}

.activation-step3 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .email-mac-address {
    width: 300px;
    margin-top: 70px;
  }
}

.activation-step4 {
  width: 770px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 50vh;

  section {
    display: flex;
    width: 100%;
    margin-top: 40px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    width: 50%;
    border-right: 2px solid white;
    padding-right: 20px;
    padding: 20px;
    gap: 15px;

    .button {
      width: 100% !important;
      padding: 10px;
      background-color: transparent;
      text-align: center;
      border-radius: 5px;
      font-size: 20px;
      font-weight: 600;
      color: $text;
      border: 2px solid $text;
      background-color: transparent;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.9;
      }

      &.active {
        background-color: $text;
        color: $app;
      }
    }
  }

  .container {
    width: 59%;
    height: 100%;
    padding: 20px;
  }
}

.stripe-paypal-desibled-text {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-top: 20px;
    font-size: 20px;
    color: #fd7072 !important;
  }
}

.agree-with-all-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: $active-color;
  border-color: $active-color;
}
.coupon-activation-activate-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

@media screen and (max-width: 772px) {
  .packages-list {
    // grid-template-columns: 1fr;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .package-list_title {
    font-size: 25px !important;
  }
}

.container-btn-whatsapp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-btn {
  font-size: 0.875rem; // equivalent to text-sm
  padding: 0.5rem 0.75rem; // equivalent to px-3 py-2
  margin-top: 0.75rem;
  display: inline-flex;
  align-items: center;
  text-align: center;
  transition: color 0.3s ease;
  cursor: pointer;
  background: transparent !important;
  border: none !important;
  margin-left: 7rem;
}

.connect-btn img {
  width: 30px;
  height: 30px;
}
